import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { LamfoApiTeamsListResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import { Loader } from "../../components/loader/Loader";
import { Button, Row, Image, Col, Stack } from "react-bootstrap";
import styles from "./team.module.scss";

const repository = new LamfoApiTeamRepository();

export function Team() {
	const [teamListResponse, setTeamListResponse] = useState<LamfoApiTeamsListResponse>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		repository
			.getTeamsList('playing')
			.then((teamsListResponse) => {
				setTeamListResponse(teamsListResponse);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [repository]);

	if (isLoading) {
		return (
			<React.Fragment>
				<Loader />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{
				teamListResponse?.divisions.map((division, divisionIndex) => (
					<Stack key={divisionIndex} gap={1}>
						<h2 className="text-center mb-0 mt-2">{division.name}</h2>
						{
							division.teams.reduce((teamsChunks: JSX.Element[][], team, teamIndex) => {
								const chunkIndex = Math.floor(teamIndex / 4);
								if (!teamsChunks[chunkIndex]) {
								teamsChunks[chunkIndex] = [];
								}
								teamsChunks[chunkIndex].push(
								<Link key={teamIndex} to={`/team/${team.code}`}>
									<Button className={styles.teambutton}>
										<Image src={team.shield} className={styles.shieldimg} />
									</Button>
								</Link>
								);
								return teamsChunks;
							}, [[]] as JSX.Element[][]).map((teamsChunk, chunkIndex) => (
								<Stack key={chunkIndex} direction="horizontal" gap={1} className="m-auto">
									{teamsChunk}
								</Stack>
							))
						}
					</Stack>
				))
			}	
		</React.Fragment>
	);
}
