import React, { useEffect, useState } from "react";

import { LamfoApiEconomyResumesResponse } from "../../infrastructure/LamfoApiResponses";
import styles from "./economy.module.scss";
import { Tab, Table, Tabs } from "react-bootstrap";
import { Loader } from "../../components/loader/Loader";
import { LamfoApiEconomyRepository } from "../../infrastructure/LamfoApiEconomyRepository";
import Image from 'react-bootstrap/Image';

const repository = new LamfoApiEconomyRepository();

export function Economy() {
	const [economiesResponse, setEconomiesResponse] = useState<LamfoApiEconomyResumesResponse>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setIsLoading(true);
		repository
			.getTeamsEconomyResume()
			.then((economyResponse) => {
				setEconomiesResponse(economyResponse);
				setIsLoading(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [repository]);

	const millionNumberFormat = (amount: number) => {
		return (amount / 1000000).toFixed(2);
	};

	return (
		<React.Fragment>
			{isLoading && <Loader />}
			<Tabs
				defaultActiveKey="pra"
				id="justify-tab-example"
				className={styles.maintab}
				variant="pills"
				justify
			>
				{
					[
						{ key: 'pra', title: 'PR A', teams: economiesResponse?.pra ?? [] },
						{ key: 'pbn', title: 'NA B', teams: economiesResponse?.pbn ?? [] },
						{ key: 'prb', title: 'PR B', teams: economiesResponse?.prb ?? [] },
						{ key: 'prc', title: 'PR C', teams: economiesResponse?.prc ?? [] }
					].map((tab, index) => {
						return (
							<Tab key={index} eventKey={tab.key} title={tab.title} className="text-center">
								<Table bordered hover size="sm" className={styles.econtable}>
									<thead>
										<tr>
											<th></th>
											<th>INI</th>
											<th>ING</th>
											<th>GAS</th>
											<th>TOT</th>
										</tr>
									</thead>
									<tbody>
										{
											tab.teams.map((team, index) => {
												return (
													<tr key={index}>
														<td className={styles.tablecell}>
															<a href={process.env.REACT_APP_LAMFO_OLD_URL + 'deteconomia.php?id=' + team.code}>
																<Image
																	src={team.shield ?? '/img/shield.png'}
																	className={styles.shieldimg}
																/>
															</a>
														</td>
														<td className={styles.balancecell + ' ' + ((team.economy?.resume.start_balance ?? 0) < 0 ? (' ' + styles.negativebalance) : '')  }>
															{millionNumberFormat(team.economy?.resume.start_balance ?? 0)}
														</td>
														<td className={styles.incomecell}>{millionNumberFormat(team.economy?.resume.total_income ?? 0)}</td>
														<td className={styles.expensecell}>{millionNumberFormat(team.economy?.resume.total_expenses ?? 0)}</td>
														<td className={styles.balancecell + ' ' + styles.totalcell + ((team.economy?.resume.balance ?? 0) < 0 ? (' ' + styles.negativebalance) : '')  }>
															{millionNumberFormat(team.economy?.resume.balance ?? 0)}
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</Table>
							</Tab>
						);
					})
				}
			</Tabs>
		</React.Fragment>
	);
}
