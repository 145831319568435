import { LamfoApiCheckRegistrationResponse, LamfoApiEmptyResponse } from "./LamfoApiResponses";

export class LamfoApiSeasonRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getSeasonRegisterEndpoint = "$apiUrl/private/registration";
	private readonly getRegisterCheckEndpoint = "$apiUrl/private/registration/check";

	async putUserRegister(): Promise<LamfoApiEmptyResponse> {
		const repositoryRequest = fetch(
			this.getSeasonRegisterEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "PUT",
				credentials: "include"
			}
		);

		return repositoryRequest
			.then((response) => response);
	}

	async checkUserRegistration(): Promise<LamfoApiCheckRegistrationResponse> {
		const repositoryRequest = fetch(
			this.getRegisterCheckEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "GET",
				credentials: "include"
			}
		);

		return repositoryRequest
			.then((response) => {
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((registration) => {
				return {
					registered: registration.registered as boolean,
				};
			}).catch((error) => {
				throw error;
			});
	}
}
