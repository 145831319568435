import React, { useContext, useEffect, useState } from "react";

import { LamfoApiTeamResponse, Offer, OfferPlayer, Player } from "../../infrastructure/LamfoApiResponses";
import { Alert, Form } from "react-bootstrap";
import { createEmptyOffer, createEmptyOfferPlayer } from "../../infrastructure/LamfoEmptyThings";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import UserContext from "../../contexts/UserContext";
import { FaTrash } from "react-icons/fa";
import styles from "./offerform.module.scss";
import { LamfoApiOfferRepository } from "../../infrastructure/LamfoApiOfferRepository";
import MoneyFormField from "../moneyFormField/MoneyFormField";
import { is } from "cypress/types/bluebird";
import { set } from "cypress/types/lodash";

const teamRepository = new LamfoApiTeamRepository();
const offerRepository = new LamfoApiOfferRepository();

export function OfferForm(props: { 
    onSubmit: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    player: Player
}) {
    const { onSubmit, player } = props;
    const userData = useContext(UserContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [offer, setOffer] = useState<Offer>(createEmptyOffer());
    const [offerPlayer, setOfferPlayer] = useState<OfferPlayer>(createEmptyOfferPlayer());
    const [teamResponse, setTeamResponse] = useState<LamfoApiTeamResponse>();

    useEffect(() => {
        if (userData.success && userData.team.code !== 'nin') {
            setIsLoading(true);
            teamRepository
                .getTeam(userData.team.code)
                .then((teamResponse) => {
                    setTeamResponse(teamResponse);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [teamRepository, userData]);

    const changeOfferType = (type: string) => {
        setOffer({ ...createEmptyOffer(), type });
    }

    const sendOffer = (event: React.FormEvent<HTMLFormElement>) => {
        setIsLoading(true);
        event.preventDefault();
        offerRepository
            .sendOffer({ ...offer, player_code: player.code })
            .then(() => {
                onSubmit(event as unknown as React.ChangeEvent<HTMLSelectElement>);
                setIsLoading(false);
                alert("Oferta enviada");
            })
            .catch((error) => {
                setIsLoading(false);
                alert(error);
            });
    }

    return (
        <React.Fragment>
            <h3>Enviar oferta por {player.player_data.short_name} {player.player_data.short_surname}</h3>
            <Alert className={styles.salary}>
                El jugador percibiría un salario inicial por temporada de <strong>{player.calculated_salary.toLocaleString()}</strong>.
            </Alert>
            <Form onSubmit={sendOffer}>
                <Form.Group controlId="offerType">
                    <Form.Label>Tipo de oferta</Form.Label>
                    <Form.Control
                        as="select"
                        value={offer.type}
                        onChange={(e) => changeOfferType(e.target.value)}
                    >
                        <option value="C">Compra</option>
                        <option value="P">Préstamo</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    controlId="price"
                    className="mt-3"
                >
                    <Form.Label>Precio</Form.Label>
                    <MoneyFormField
                        value={offer.amount}
                        onChange={(e) => setOffer({ ...offer, amount: e })}
                    />
                </Form.Group>

                {teamResponse && (
                    <>
                        <Form.Group controlId="additionalPlayers" className="mt-3">
                            <Form.Label>Añadir jugadores</Form.Label>
                            {
                                isLoading ?
                                <Form.Control
                                    as="select"
                                >
                                    <option value="">Cargando...</option>
                                </Form.Control> :
                                <Form.Control
                                    as="select"
                                    value={offerPlayer.player_code}
                                    onChange={(e) => setOfferPlayer({ ...offerPlayer, player_code: e.target.value })}
                                >
                                    <option value="">Seleccionar jugador</option>
                                    {teamResponse.team.staff
                                        .filter((teamPlayer) => !offer.players?.some((p) => p.player_code === teamPlayer.code))
                                        .map((teamPlayer, index) => (
                                            <option key={index} value={teamPlayer.code}>
                                                {teamPlayer.player_data.short_name} {teamPlayer.player_data.short_surname}
                                            </option>
                                        ))}
                                </Form.Control>
                            }
                        </Form.Group>
                        <div className="d-flex mt-1">
                            <Form.Group controlId="additionalPlayerType" className="col-8">
                                <Form.Control
                                    as="select"
                                    className="me-2"
                                    value={offerPlayer.type}
                                    onChange={(e) => setOfferPlayer({ ...offerPlayer, type: e.target.value })}
                                >
                                    <option value="">Tipo</option>
                                    <option value="C">Traspaso</option>
                                    <option value="P">Préstamo</option>
                                </Form.Control>
                            </Form.Group>
                            <button
                                type="button"
                                className="btn btn-primary col-4"
                                disabled={offerPlayer.player_code === "" || offerPlayer.type === ""}
                                onClick={() => {
                                    if (offerPlayer.player_code && offerPlayer.type) {
                                        setOffer({
                                            ...offer,
                                            players: [
                                                ...offer.players ?? [],
                                                offerPlayer,
                                            ],
                                        });
                                        setOfferPlayer(createEmptyOfferPlayer());
                                    }
                                }}
                            >
                                Añadir
                            </button>
                        </div>
                        <ul className="mt-3">
                            {offer.players && offer.players.map((player, index) => (
                                <li key={index}>
                                    {teamResponse.team.staff.find((p) => p.code === player.player_code)?.player_data.short_name}{" "}
                                    {teamResponse.team.staff.find((p) => p.code === player.player_code)?.player_data.short_surname}{" "}
                                    - {player.type === "C" ? "Traspaso" : "Préstamo"}
                                    <FaTrash
                                        className="ms-2"
                                        onClick={() => {
                                            setOffer({
                                                ...offer,
                                                players: offer.players ? offer.players?.filter((p, i) => i !== index) : [],
                                            });
                                        }}
                                    />
                                </li>
                            ))}
                        </ul>
                    </>
                )}
                {offer.type === "P" && (
                    <>
                        <Form.Group
                            controlId="buyOption"
                            className="mt-3"
                        >
                            <Form.Check 
                                type="checkbox" 
                                label="Incluir opción de compra" 
                                checked={offer.loan_buy_option_amount !== null}
                                onChange={(e) => setOffer({ ...offer, loan_buy_option_amount: e.target.checked ? 0 : null })}
                            />
                            {offer.loan_buy_option_amount !== null && (
                                <MoneyFormField
                                    value={offer.loan_buy_option_amount}
                                    onChange={(e) => setOffer({ ...offer, loan_buy_option_amount: e })}
                                /> 
                            )}
                        </Form.Group>
                        <Form.Group
                            controlId="renewalOption"
                            className="mt-3"
                        >
                            <Form.Check 
                                type="checkbox" 
                                label="Incluir opción de renovación" 
                                checked={offer.loan_renewal_amount !== null}
                                onChange={(e) => setOffer({ ...offer, loan_renewal_amount: e.target.checked ? 0 : null })}
                            />
                            {offer.loan_renewal_amount !== null && (
                                <MoneyFormField
                                    value={offer.loan_renewal_amount}
                                    onChange={(e) => setOffer({ ...offer, loan_renewal_amount: e })}
                                />
                            )}
                        </Form.Group>
                        <Form.Group
                            controlId="minutesClause"
                            className="mt-3"
                        >
                            <Form.Check
                                type="checkbox" 
                                label="Incluir cláusula de minutos" 
                                checked={offer.loan_time_clause_minutes !== null && offer.loan_time_clause_amount !== null}
                                onChange={(e) => setOffer({
                                    ...offer,
                                    loan_time_clause_minutes: e.target.checked ? 0 : null,
                                    loan_time_clause_amount: e.target.checked ? 0 : null
                                })}
                            />
                            {(offer.loan_time_clause_minutes !== null && offer.loan_time_clause_amount !== null) && (
                                <>
                                    <Form.Control
                                        className="mt-1"
                                        required
                                        type="number"
                                        placeholder="Minutos mínimos"
                                        value={offer.loan_time_clause_minutes == 0 ? "" : offer.loan_time_clause_minutes}
                                        onChange={(e) => setOffer({ ...offer, loan_time_clause_minutes: parseInt(e.target.value) })}
                                    />
                                    <MoneyFormField
                                        value={offer.loan_time_clause_amount}
                                        onChange={(e) => setOffer({ ...offer, loan_time_clause_amount: e })}
                                    />
                                </>
                            )}
                        </Form.Group>
                    </>
                )}
                <button
                    type="submit"
                    className="btn btn-primary w-100 mt-2"
                    disabled={isLoading}
                >
                    Enviar
                </button>
            </Form>
        </React.Fragment>
    );
}