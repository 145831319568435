import React from "react";

import { ManagersRanking } from "../../components/managersRanking/ManagersRanking";

export function Ranking() {
	return (
		<React.Fragment>
			<h1 className="text-center mt-3">Ranking de managers</h1>
			<ManagersRanking />
		</React.Fragment>
	);
}