import React, { useEffect, useState } from "react";

import { LamfoApiTransfersResponse, PlayerTransfer } from "../../infrastructure/LamfoApiResponses";
import styles from "./transfers.module.scss";
import 'react-loading-skeleton/dist/skeleton.css'
import Image from 'react-bootstrap/Image';
import { Card, Col, Pagination, Row, Table, Toast } from "react-bootstrap";
import { createEmptyTransfersResponse } from "../../infrastructure/LamfoEmptyThings";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "react-router-dom";
import { LamfoApiOfferRepository } from "../../infrastructure/LamfoApiOfferRepository";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Form } from "react-bootstrap";

const repository = new LamfoApiOfferRepository();

export function Transfers() {
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1'));
	const [transfersResponse, setTransfersResponse] = useState<LamfoApiTransfersResponse>(createEmptyTransfersResponse());
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showToastIndex, setShowToastIndex] = useState<number | null>(null);
	const [transferType, setTransferType] = useState<string>(searchParams.get('type') || '');
	const [transferTypePrev, setTransferTypePrev] = useState<string>(searchParams.get('type') || '');
	
		
	const toggleShowToast = (index: number) => {
		setShowToastIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const transformTransferType = (type: string) => {
		switch (type) {
			case 'C': // Compra en ofertas
				return 'Compra';
			case 'T': // Compra en intercambio
				return 'Traspaso';
			case 'H': // Contratación de jugador libre
				return 'Contratación';
			case 'O':
				return 'Op. compra';
			case 'P': // Préstamo
			case 'P6': // Préstamo 6 meses
			case 'P12': // Préstamo 12 meses
				return 'Préstamo';
			case 'L':
				return 'Libre';
			case 'J':
				return 'Juvenil';
			case 'R':
				return 'Retiro';
			case 'D':
				return 'Fallecimiento';
			default:
				return 'Desconocido'
		}
	}

	const getTeamFromImage = (transfer: PlayerTransfer) => {
		switch (transfer.type) {
			case 'J':
				return '/img/youth.png';
			case 'H':
				return '/img/jobless.png';
			default:
				return transfer.team_from?.code === 'ext' ?
					'/img/world.png' :
					transfer.team_from?.shield;
		}
	}

	const getTeamToImage = (transfer: PlayerTransfer) => {
		switch (transfer.type) {
			case 'L':
				return '/img/jobless.png';
			case 'R':
				return '/img/retired.png';
			case 'D':
				return '/img/dead.png';
			default:
				return transfer.team_to?.code === 'ext' ?
					'/img/world.png' :
					transfer.team_to?.shield;
		}
	}

	useEffect(() => {
		const params = new URLSearchParams();

		if (page > 1) {
			params.append('page', page.toString());
		}

		if (transferType !== '') {
			params.append('type', transferType);
		}

		setSearchParams(params);
	}, [transferType, page]);

	useEffect(() => {
		if (transferType !== transferTypePrev && page > 1) {
			setPage(1);
		} else {
			setIsLoading(true);
			window.scrollTo({
				top: 0,
			});
			repository
				.getTransfers(
					transferType,
					page
				)
				.then((transfersResponse) => {
					setTransfersResponse(transfersResponse);
					const avatarsUrls = transfersResponse.data.map(transfer => transfer.player.avatar);
					preloadImages(avatarsUrls)
						.then(() => {
							setIsLoading(false);
						})
						.catch(() => {
							setIsLoading(false);
						});
				})
				.catch((error) => {
					alert(`${error}`);
					setTransfersResponse(createEmptyTransfersResponse());
					setIsLoading(false);
				});
		}
		setTransferTypePrev(transferType);
	}, [transferType, page]);

	const preloadImages = (imageUrls: string[]) => {
		return Promise.all(
			imageUrls.map((url) => {
				return new Promise((resolve, reject) => {
					const img = document.createElement('img');
					img.src = url;
					img.onload = resolve;
					img.onerror = reject;
				});
			})
		);
	};

	let lastDayHour = '';

	return (
		<React.Fragment>
			<h1 className="text-center mt-3">Mercado de pases</h1>
			<Form.Select
				className="mt-3 mb-3"
                value={transferType}
				onChange={e => setTransferType(e.target.value)}
				aria-label="review-team-select"
			>
                <option value=''>Todos</option>
				<option value='C'>Compras</option>
				<option value='P'>Préstamos</option>
				<option value='O'>Usos de opción</option>
				<option value='L'>Liberados</option>
				<option value='H'>Contratados libres</option>
				<option value='J'>Juveniles</option>
				<option value='R'>Retiros</option>
				<option value='D'>Fallecimientos</option>
            </Form.Select>
			{isLoading &&
				<React.Fragment>
					<Card className={styles.transfercard}>
						<Card.Header className={styles.cardheader}>
							<Row>
								<div className={`${styles.datetext} d-flex align-items-center`}>
									<Skeleton width={120} />
								</div>
							</Row>
						</Card.Header>
					</Card>
					{ Array.from({ length: 30 }).map((_, index) => (
						<Card key={index} className={styles.transfercard}>
							<Card.Header className={styles.cardheader}>
								<Row>
									<Col className="col-2 p-0 text-center">
										<Image
											src={'/img/avatar.png'}
											roundedCircle
											className={styles.avatar}
										/>
									</Col>
									<Col className="col-5 p-0">
										<div className={styles.playertext}>
											<Skeleton width={90} />
										</div>
										<div className={`${styles.playertext} d-flex align-items-center`}>
											<Skeleton width={60} />
										</div>
									</Col>
									<Col className="col-5 p-0 text-center">
										<Image
											src={'/img/shield.png'}
											className={styles.teamlogo}
										/>
										<Image
											src={'img/arrow-change.png'}
											className={styles.arrow}
										/>
										<Image
											src={'/img/shield.png'}
											className={styles.teamlogo}
										/>
									</Col>
								</Row>
							</Card.Header>
						</Card>
					))}
				</React.Fragment>
			}
			{!isLoading &&
				<React.Fragment>
					{transfersResponse.data.map((transfer, index) => (
						<React.Fragment>
							{lastDayHour !== new Date(transfer.date).toLocaleDateString('es-ES', { day: '2-digit', month: 'short', hour: '2-digit' }) && (
								lastDayHour = new Date(transfer.date).toLocaleDateString('es-ES', { day: '2-digit', month: 'short', hour: '2-digit' }),
								<Card key={index} className={styles.transfercard}>
									<Card.Header className={styles.cardheader}>
										<Row>
											<div className={`${styles.datetext} d-flex align-items-center`}>
												{lastDayHour !== transfer.date && (
													<Col className="col-12 p-0 text-center">
														{lastDayHour}:00 hs
													</Col>
												)}
											</div>
										</Row>
									</Card.Header>
								</Card>
							)}

							<Card key={index} className={styles.transfercard}>
								<Card.Header className={styles.cardheader}>
									<Row>
										<Col className="col-2 p-0 text-center">
											<Image
												src={transfer.player.avatar ?? '/img/avatar.png'}
												roundedCircle
												className={styles.avatar}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null;
													currentTarget.src="/img/avatar.png";
												}}
											/>
										</Col>
										<Col className="col-5 p-0">
											<div className={styles.playertext}>
												{transfer.player.name} {transfer.player.surname}
											</div>
											{
												<div className={`${styles.playertext} d-flex align-items-center`}>
													{
														['C', 'P', 'O'].includes(transfer.type) && (
														showToastIndex !== index
															? <FaPlus className={styles.toasticon} onClick={() => toggleShowToast(index)} />
															: <FaMinus className={styles.toasticon} onClick={() => toggleShowToast(index)} />
													)}
													<div>{transformTransferType(transfer.type)}</div>
												</div>
											}
										</Col>
										<Col className="col-5 p-0 text-center">
											<Image
												src={getTeamFromImage(transfer) ?? '/img/shield.png'}
												className={styles.teamlogo}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null;
													currentTarget.src="/img/shield.png";
												}}
											/>
											<Image
												src={ 'img/arrow-change.png' }
												className={styles.arrow}
											/>
											<Image
												src={getTeamToImage(transfer) ?? '/img/shield.png'}
												className={styles.teamlogo}
												onError={({ currentTarget }) => {
													currentTarget.onerror = null;
													currentTarget.src="/img/shield.png";
												}}
											/>
										</Col>
									</Row>
									<Row>
										<Toast className="mt-2 p-1 w-100" show={showToastIndex === index} onClose={() => toggleShowToast(index)}>
											<Toast.Body className="p-1">
												<ul className="m-0">
													{transfer.amount == 0 ?
														<li>Sin cargo</li> :
														<li>Monto: {transfer.amount?.toLocaleString()}</li>
													}
													{(transfer.players && transfer.players.length > 0) &&
														<li>
															Jugadores:
															<ul>
																{transfer.players.map((player, index) => (
																	<li key={index}>{player.player.name} {player.player.surname} ({transformTransferType(player.type)})</li>
																))}
															</ul>
														</li>
													}
													{transfer.loan_buy_option_amount && <li>Opción de compra: {transfer.loan_buy_option_amount.toLocaleString()}</li>}
													{transfer.loan_renewal_amount && <li>Renovación: {transfer.loan_renewal_amount.toLocaleString()}</li>}
													{transfer.loan_time_clause_minutes && <li>Cláusula de minutos: {transfer.loan_time_clause_minutes}/{transfer.loan_time_clause_amount?.toLocaleString()}</li>}
												</ul>
											</Toast.Body>
										</Toast>
									</Row>
								</Card.Header>
							</Card>
						</React.Fragment>
					))}
					<Pagination className="justify-content-center">
						{ page > 1 && <Pagination.Prev onClick={() => setPage(page - 1)} /> }
						<Pagination.Item>Página {page}</Pagination.Item>
						{ page < transfersResponse.pagination.total_pages && <Pagination.Next onClick={() => setPage(page + 1)} /> }
					</Pagination>
				</React.Fragment>
			}
		</React.Fragment>
	);
}
