import React, { useContext, useEffect, useState } from "react";

import { Action, Player } from "../../infrastructure/LamfoApiResponses";
import { Button, Card, Col, Dropdown, Form, Modal, Offcanvas, Row, Stack } from "react-bootstrap";
import styles from "./playerslist.module.scss";
import { PlayerCardHeader } from "../playerCardHeader/PlayerCardHeader";
import UserContext from "../../contexts/UserContext";
import { createEmptyAction, createEmptyPlayer } from "../../infrastructure/LamfoEmptyThings";
import Image from 'react-bootstrap/Image';
import { LamfoApiPlayerRepository } from "../../infrastructure/LamfoApiPlayerRepository";
import { OfferForm } from "../offerForm/OfferForm";
import { LamfoApiOfferRepository } from "../../infrastructure/LamfoApiOfferRepository";
import MoneyFormField from "../moneyFormField/MoneyFormField";

const playerRepository = new LamfoApiPlayerRepository();
const offerRepository = new LamfoApiOfferRepository();

export function PlayersList(props: {
	teamCode: string,
    players: Player[]
}) {
    const { teamCode, players } = props;
	const userData = useContext(UserContext);
	const [playerDetail, setPlayerDetail] = useState<Player>(createEmptyPlayer());
	const [showPlayerDetail, setShowPlayerDetail] = useState(false);
	const [playerAction, setPlayerAction] = useState<Player>(createEmptyPlayer());
	const [playerToSetTransferPrice, setPlayerToSetTransferPrice] = useState<Player>(createEmptyPlayer());
	const [playerToSendOffer, setPlayerToSendOffer] = useState<Player>(createEmptyPlayer());
	const [transferPrice, setTransferPrice] = useState<number>(0);
	const [confirmAction, setConfirmAction] = useState<Action>(createEmptyAction());
	const [showTransferPriceModal, setShowTransferPriceModal] = useState(false);
	const [showSendOfferModal, setShowSendOfferModal] = useState(false);
	const [showActionConfirm, setShowActionConfirm] = useState(false);
	const [playerLoan, setPlayerLoan] = useState<Player>();
	const [showLoan, setShowLoan] = useState(false);
	const [teamShields, setTeamShields] = useState<ImageMap>({});

	type ImageMap = {
		[key: string]: HTMLImageElement;
	};

	const handleShowPlayerDetail = (player: Player) => {
		setPlayerDetail(player);
		setShowPlayerDetail(true);
	}
	const handleClosePlayerDetail = () => setShowPlayerDetail(false);

	const handlePlayerAction = (action: Action, player: Player) => {
		switch (action.type) {
			case 'unset-transfer-price':
				playerRepository
					.deleteTransferPrice(player.code)
					.then(() => {
						player.player_data.transfer_price = undefined;
						setShowActionConfirm(false);
					})
					.catch((error) => {
						console.log(error);
					});
				break;
			case 'set-transfer-price':
				handleShowTransferPriceModal(player);
				break;
			case 'send-offer':
				handleShowSendOfferModal(player);
				break;
			case 'buy-by-clause':
				offerRepository
					.buyPlayerByClause(player.code)
					.then(() => {
						setShowActionConfirm(false);
						alert('La transferencia ha sido realizada con éxito.');
						window.location.reload();
					})
					.catch((error) => {
						alert(error.message);
					});
				break;
			case 'buy-by-transfer-price':
				offerRepository
					.buyPlayerByTransferPrice(player.code)
					.then(() => {
						setShowActionConfirm(false);
						alert('La transferencia ha sido realizada con éxito.');
						window.location.reload();
					})
					.catch((error) => {
						alert(error.message);
					});
				break
			default:
				alert('Se ha procesado una acción desconocida. Comunicarlo al líder supremo: Gato. Código de error: ' + action.type);
		}
	}

	const handleShowActionConfirm = (action: Action, player: Player) => {
		setPlayerAction(player);
		setConfirmAction(action);
		setShowActionConfirm(true);
	}

	const handleCloseActionConfirm = () => setShowActionConfirm(false);

	const handleShowTransferPriceModal = (player: Player) => {
		setPlayerToSetTransferPrice(player);
		setTransferPrice(player.player_data.transfer_price ?? 0);
		setShowTransferPriceModal(true);
	}

	const handleCloseTransferPriceModal = () => setShowTransferPriceModal(false);

	const handleShowSendOfferModal = (player: Player) => {
		setPlayerToSendOffer(player);
		setShowSendOfferModal(true);
	}

	const handleCloseSendOfferModal = () => setShowSendOfferModal(false);

	const handleSetTransferPrice = (player: Player, price: number) => {
		playerRepository
			.setTransferPrice(player.code, price)
			.then(() => {
				player.player_data.transfer_price = price;
				setShowTransferPriceModal(false);
			})
			.catch((error) => {
				console.log(error);
			});
	}

	const handleShowLoan = (playerLoan: Player) => {
		setPlayerLoan(playerLoan);
		setShowLoan(true);
	}
	const handleCloseLoan = () => setShowLoan(false);

	useEffect(() => {
		const imageMap: ImageMap = {};
		players.forEach((player) => {
			if (player.loan) {
				const imgLoanedTeamShield = document.createElement('img');
				imgLoanedTeamShield.src = player.loan.team.shield;
				const loanedShieldTeamCode = player.loan.team.code; 
				imgLoanedTeamShield.onload = () => {
					imageMap[loanedShieldTeamCode] = imgLoanedTeamShield;
				};

				const imgOwnerTeamShield = document.createElement('img');
				imgOwnerTeamShield.src = player.loan.owner_team.shield;
				const ownerShieldTeamCode = player.loan.owner_team.code; 
				imgOwnerTeamShield.onload = () => {
					imageMap[ownerShieldTeamCode] = imgOwnerTeamShield;
				};
			}
		});
		setTeamShields(imageMap);
	}, [players]);

    return (
        <React.Fragment>
			{players.map((player, index) => (
				<Card key={index} className="mb-0">
					<PlayerCardHeader
						onClick={() => handleShowPlayerDetail(player)}
						player={player}
						teamCode={teamCode}
					/>
					{
						userData.admin ?
							<Card.Footer>
								{userData.admin ?
									<div className={styles.footertext}>{player.code}</div> :
									''
								}
							</Card.Footer> : ''
					}
				</Card>
			))}
			<Modal
				show={showPlayerDetail}
				onHide={handleClosePlayerDetail}
				keyboard={false}
				size="lg"
				centered
			>
				<Card className="mb-3">
					<PlayerCardHeader
						onClick={() => handleClosePlayerDetail}
						player={playerDetail}
						teamCode={teamCode}
						isDetail
					/>
					<Card.Body className="p-0">
						<Row>
							<Col className="col-4 p-0">
								<Stack gap={1} className="p-2">
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>AGE</div>
										<div className={styles.stat + ' ' + styles.statnumber}>{playerDetail?.age}</div>
									</Stack>
									{[
										{name: "GK", value: playerDetail?.skills.gk},
										{name: "DF", value: playerDetail?.skills.tk},
										{name: "MF", value: playerDetail?.skills.ps},
										{name: "FW", value: playerDetail?.skills.sh},
									].map((skill, index) => (
										<Stack key={index}  direction="horizontal" gap={2}>
											<div className={styles.stat}>{skill.name}</div>
											<div className={styles.stat + ' ' + styles.statnumber}>{skill.value}</div>
										</Stack>
									))}
								</Stack>
							</Col>
							<Col className="col-4 p-0">
								<Stack gap={1} className="p-2">
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>MOR</div>
										<div className={styles.stat + ' ' + styles.statnumber}>
											<Image
												src={'/img/moral_' + playerDetail?.stats.happyness + '.png'}
												className={styles.staticon}
											/>
										</div>
									</Stack>
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>TAR</div>
										<div className={styles.stat + ' ' + styles.statnumber}>
											{(playerDetail?.stats.suspended_matches ?? 0) > 0 ?
												<div className={styles.card + ' ' + styles.redcard} /> :
												(playerDetail?.stats.yellow_cards ?? 0) % 5 > 0 ?
														<Stack direction="horizontal" gap={1}>
															<div className={styles.card + ' ' + styles.yellowcard} />
															{(playerDetail?.stats.yellow_cards ?? 0) % 5}
														</Stack> :
														''
											}
										</div>
									</Stack>
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>LES</div>
										<div className={styles.stat + ' ' + styles.statnumber}>
											{(playerDetail?.stats.injury_weeks ?? 0) > 0 ?
												<Stack direction="horizontal" gap={0}>
													<Image
														src="/img/pharmacy.png"
														roundedCircle
														className={styles.staticon}
													/>
													{playerDetail?.stats.injury_weeks}
												</Stack> :
												'NO'
											}
										</div>
									</Stack>
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>FIT</div>
										<div className={styles.stat + ' ' + styles.statnumber}>{playerDetail?.stats.fitness}</div>
									</Stack>
									<Stack direction="horizontal" gap={2}>
										<div className={styles.stat}>RTM</div>
										<div className={styles.stat + ' ' + styles.statnumber}>{playerDetail?.stats.rithm}</div>
									</Stack>
								</Stack>
							</Col>
							<Col className="col-4 p-0">
								<Stack gap={1} className="p-2">
									{[
										{name: "MIN", value: playerDetail?.stats.minutes},
										{name: "ATA", value: playerDetail?.stats.saves},
										{name: "GOL", value: playerDetail?.stats.goals},
										{name: "ASI", value: playerDetail?.stats.assistances},
									].map((skill, index) => (
										<Stack key={index} direction="horizontal" gap={2}>
											<div className={styles.stat}>{skill.name}</div>
											<div className={styles.stat + ' ' + styles.statnumber}>{skill.value}</div>
										</Stack>
									))}
								</Stack>
								{(playerDetail?.actions.length ?? 0) > 0 ?
									<Stack direction="horizontal" gap={2}>
										<Dropdown>
											<Dropdown.Toggle size="sm" variant="primary" id="actions-dropdown">
												Acción
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{playerDetail?.actions.map((action, index) => (
													action.confirmation
														? <Dropdown.Item
															key={index}
															onClick={action.confirmation ? () => handleShowActionConfirm(action, playerDetail) : () => {}}
														>
															{action.label}
														</Dropdown.Item>
														: (action.url
															? <Dropdown.Item
																key={index}
																href={process.env.REACT_APP_LAMFO_OLD_URL + action.url}
															>
																{action.label}
															</Dropdown.Item>
																: <Dropdown.Item
																key={index}
																onClick={() => handlePlayerAction(action, playerDetail)}
															>
																{action.label}
															</Dropdown.Item>
														)
												))}
											</Dropdown.Menu>
										</Dropdown>
									</Stack> : 
									''
								}
							</Col>
						</Row>
					</Card.Body>
					<Card.Footer>
						{playerDetail?.loan ?
							<div>
								{
									<div className={styles.footertext}>
										A préstamo 
										{playerDetail?.loan.owner_team.code === teamCode ?
											' en ' +  playerDetail?.loan.team.name + ' ' :
											' de ' +  playerDetail?.loan.owner_team.name + ' '}
										(<span className={styles.linktext} onClick={() => handleShowLoan(playerDetail)}>Ver contrato</span>)
									</div>
								}
							</div> :
							''
						}
						<div className={styles.footertext}>Salario: ${playerDetail.calculated_salary.toLocaleString()}</div>
						{!playerDetail?.loan && playerDetail?.contract?.termination_clause ?
							<div className={styles.footertext}>Cláusula de rescisión: ${playerDetail?.contract.termination_clause.toLocaleString()}</div> :
							''
						}
						{!playerDetail?.loan && playerDetail?.player_data.transfer_price ?
							<div className={styles.footertext}>En venta por: ${playerDetail?.player_data.transfer_price.toLocaleString()}</div> :
							''
						}
						<div className={styles.footertext}>Equipo formador: {playerDetail?.player_data.former_team}</div>
						{userData.admin ?
							<div className={styles.footertext}>{playerDetail?.code}</div> :
							''
						}
					</Card.Footer>
				</Card>
      		</Modal>
			<Modal
				show={showActionConfirm}
				onHide={handleCloseActionConfirm}
				backdrop="static"
				keyboard={false}
				centered
			>
        		<Modal.Header closeButton>
          			<Modal.Title>Atención</Modal.Title>
        		</Modal.Header>
        		<Modal.Body>
          			{ confirmAction.confirmation_text
						? confirmAction.confirmation_text
						: '¿Estás seguro que deseas ' + confirmAction.label.toLowerCase() + ' a ' + playerAction.player_data.name + ' ' + playerAction.player_data.surname + '? Esta acción no se puede deshacer.' }
        		</Modal.Body>
        		<Modal.Footer>
					{confirmAction?.url
						? <Button variant="primary" href={(process.env.REACT_APP_LAMFO_OLD_URL ?? '') + confirmAction.url}>Confirmar</Button>
						: <Button variant="primary" onClick={() => handlePlayerAction(confirmAction, playerAction)}>Confirmar</Button>
					}
          			<Button variant="secondary" onClick={handleCloseActionConfirm}>Cancelar</Button>
        		</Modal.Footer>
      		</Modal>
			<Modal
				show={showTransferPriceModal}
				onHide={handleCloseTransferPriceModal}
				centered
			>
        		<Modal.Body>
					<Row className="mb-2">
						<Form.Label htmlFor="inputPassword5">
							Precio de venta de {playerToSetTransferPrice?.player_data.short_name} {playerToSetTransferPrice?.player_data.short_surname}
						</Form.Label>
						<MoneyFormField
							value={transferPrice}
							onChange={setTransferPrice}
						/>
						<Form.Text muted>
							Verifique el precio seleccionado. Una vez confirmado, cualquier equipo podrá comprar al jugador sin ningún tipo de validación adicional.
						</Form.Text>
					</Row>
					<Button className="m-1" variant="primary" onClick={() => handleSetTransferPrice(playerToSetTransferPrice, transferPrice)}>Confirmar</Button>
          			<Button variant="secondary" onClick={handleCloseTransferPriceModal}>Cancelar</Button>
        		</Modal.Body>
      		</Modal>
			<Modal
				show={showSendOfferModal}
				onHide={handleCloseSendOfferModal}
				centered
			>
        		<Modal.Body>
					<OfferForm onSubmit={handleCloseSendOfferModal} player={playerToSendOffer} />
        		</Modal.Body>
      		</Modal>
			<Offcanvas show={showLoan} onHide={handleCloseLoan}>
        		<Offcanvas.Header closeButton>
          			<Offcanvas.Title>
						Contrato de préstamo
					</Offcanvas.Title>
        		</Offcanvas.Header>
        		<Offcanvas.Body>
          			Contrato de préstamo por el jugador {playerLoan?.player_data.name} {playerLoan?.player_data.surname}.
					<ul>
						<li>Fecha de finalización: {playerLoan?.loan?.loan_end_date}</li>
						{playerLoan?.loan?.buying_option ?
							<li>Opción de compra: ${playerLoan?.loan?.buying_option.toLocaleString()}</li> :
							''
						}
						{playerLoan?.loan?.renewal_option ?
							<li>Opción de renovación hasta {playerLoan?.loan?.renewal_option.loan_end_date}: ${playerLoan?.loan?.renewal_option.price.toLocaleString()}</li> :
							''
						}
						{playerLoan?.loan?.clauses?.map((clause, index) => (
							<li key={index}>{clause}</li>
						))}
					</ul>
					{	playerLoan?.loan &&
						<div className="text-center">
							{	teamShields[playerLoan.loan.team.code] &&
								<Image
									src={teamShields[playerLoan.loan.team.code].src ?? "/img/shield.png"}
									className={styles.loanshield}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src="/img/shield.png";
									}}
								/>
							}
							{	teamShields[playerLoan?.loan?.owner_team.code] &&
								<Image
									src={teamShields[playerLoan?.loan?.owner_team.code].src ?? "/img/shield.png"}
									className={styles.loanshield}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src="/img/shield.png";
									}}
								/>
							}
						</div>
					}
        		</Offcanvas.Body>
      		</Offcanvas>
        </React.Fragment>
    );
}