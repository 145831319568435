import {
	LamfoApiTransfersResponse,
	Offer,
	Pagination,
	PlayerTransfer
} from "./LamfoApiResponses";

export class LamfoApiOfferRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly transfersEndpoint = "$apiUrl/public/transfers";
	private readonly sendOfferEndpoint = "$apiUrl/private/player/offer";
	private readonly buyPlayerByClauseEndpoint = "$apiUrl/private/player/offer/termination-clause";
	private readonly buyPlayerByTransferPriceEndpoint = "$apiUrl/private/player/offer/transfer-price";

	async getTransfers(type: string, page: number): Promise<LamfoApiTransfersResponse> {

		const apiEndpoint = this.transfersEndpoint.replace("$apiUrl", this.apiUrl ?? "") + `?page=${page}` + (type && `&type=${type}`);

		const repositoryRequest = fetch(apiEndpoint);

		return repositoryRequest
			.then((response) => {
				if (response.status == 404) {
					return {
						data: [],
						pagination: {
							page: 0,
							total: 0,
							limit: 0,
						},
					};
				}
				if (!response.ok) {
					throw new Error(response.statusText);
				}
				return response.json();
			})
			.then((transfers) => {
				return {
					data: transfers.data as PlayerTransfer[],
					pagination: transfers.pagination as Pagination,
				};
			})
			.catch((error) => {
				throw error;
			});
	}

	async buyPlayerByClause(playerCode: string): Promise<void> {
		const repositoryRequest = fetch(
			this.buyPlayerByClauseEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "POST",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode }),
			}
		);

		return repositoryRequest
			.then((response) => {
				if (!response.ok) {
					return response.json().then((errorMessage) => {
						throw new Error(errorMessage.message);
					});
				}
			})
			.catch((error) => {
				throw error;
			});
	}

	async buyPlayerByTransferPrice(playerCode: string): Promise<void> {
		const repositoryRequest = fetch(
			this.buyPlayerByTransferPriceEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "POST",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ playerCode: playerCode }),
			}
		);

		return repositoryRequest
			.then((response) => {
				if (!response.ok) {
					return response.json().then((errorMessage) => {
						throw new Error(errorMessage.message);
					});
				}
			})
			.catch((error) => {
				throw error;
			});
	}

	async sendOffer(offer: Offer): Promise<void> {
		const body: any = {
			playerCode: offer.player_code,
			type: offer.type,
			amount: offer.amount,
		};

		if (offer.loan_buy_option_amount !== null) {
			body.loanBuyOptionAmount = offer.loan_buy_option_amount;
		}
		if (offer.loan_renewal_amount !== null) {
			body.loanRenewalAmount = offer.loan_renewal_amount;
		}
		if (offer.loan_time_clause_amount !== null) {
			body.loanTimeClauseAmount = offer.loan_time_clause_amount;
		}
		if (offer.loan_time_clause_minutes !== null) {
			body.loanTimeClauseMinutes = offer.loan_time_clause_minutes;
		}
		if (offer.players) {
			body.players = offer.players.map(player => ({
				playerCode: player.player_code,
				type: player.type
			}));
		}

		const repositoryRequest = fetch(
			this.sendOfferEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{
				method: "POST",
				credentials: "include",
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(body)
			}
		);

		return repositoryRequest
			.then((response) => {
				if (!response.ok) {
					return response.json().then((errorMessage) => {
						throw new Error(errorMessage.message);
					});
				}
			})
			.catch((error) => {
				throw error;
			});
	}
}
