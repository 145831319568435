import {
	LamfoApiPostResponse,
	Post
} from "./LamfoApiResponses";

export class LamfoApiPostRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getCalendarEndpoint = "$apiUrl/public/post";

	async getPosts(limit?: number): Promise<LamfoApiPostResponse> {
		const repositoryRequest = fetch(
			this.getCalendarEndpoint.replace("$apiUrl", this.apiUrl ?? "") + (limit ? `?limit=${limit}` : ''),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((postResponse) => {
				return {
					posts: postResponse['posts'] as Post[]
				};
			});
	}
}
