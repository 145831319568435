import {
	Competition,
	LamfoApiCompetitionsResponse
} from "./LamfoApiResponses";

export class LamfoApiCompetitionRepository {
	private readonly apiUrl = process.env.REACT_APP_LAMFO_API_URL;
	private readonly getActiveCompetitionsEndpoint = "$apiUrl/public/competitions/active";

	async getActiveCompetitions(teamCode?: string): Promise<LamfoApiCompetitionsResponse> {
		const repositoryRequest = fetch(
			this.getActiveCompetitionsEndpoint.replace("$apiUrl", this.apiUrl ?? ""),
			{ headers: {} }
		);

		return repositoryRequest
			.then((response) => response.json())
			.then((competitionsResponse) => {
				return {
					competitions: competitionsResponse.competitions as Competition[]
				};
			});
	}
}
