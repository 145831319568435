import React, { useEffect, useState } from "react";

import { LamfoApiTeamResponse } from "../../infrastructure/LamfoApiResponses";
import { LamfoApiTeamRepository } from "../../infrastructure/LamfoApiTeamRepository";
import { Loader } from "../../components/loader/Loader";
import { PlayersList } from "../../components/playersList/PlayersList";

const repository = new LamfoApiTeamRepository();

export function FreePlayers() {
	const teamCode = 'lib';
	const [teamResponse, setTeamResponse] = useState<LamfoApiTeamResponse>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		if (teamCode) {
			setIsLoading(true);
			repository
				.getTeam(teamCode)
				.then((teamResponse) => {
					setTeamResponse(teamResponse);
					setIsLoading(false);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}, [repository, teamCode]);

	return (
		<React.Fragment>
			{isLoading && <Loader />}
			<PlayersList
				teamCode={teamCode}
				players={teamResponse ? teamResponse.team.staff : []}
			/>
		</React.Fragment>
	);
}
