export const getDaysDifference = (dateString: string) => {
    const givenDate = new Date(dateString);
    const today = new Date();

    // Resetear horas, minutos, segundos y milisegundos para comparar solo fechas
    givenDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    const timeDifference = today.getTime() - givenDate.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // Si la diferencia es 0, significa que la fecha es hoy
    if (dayDifference === 0) {
        return "Hoy.";
    }

    // Si la diferencia es de menos de un mes (30 días)
    if (dayDifference < 30) {
        return `Hace ${dayDifference} días.`;
    }

    // Calcular la diferencia en meses
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // Los meses en JS van de 0 a 11
    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();

    const monthDifference = (currentYear - givenYear) * 12 + (currentMonth - givenMonth);

    // Si fue el mes pasado
    if (monthDifference === 1) {
        return "El mes pasado.";
    }

    // Si la diferencia es de varios meses pero menos de un año
    if (monthDifference < 12) {
        return `Hace ${monthDifference} meses.`;
    }

    // Si la diferencia es de más de un año
    return "Hace más de un año.";
}