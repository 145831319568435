import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Image, Carousel, Modal, Button } from 'react-bootstrap';
import styles from './home.module.scss';
import { LamfoApiPostRepository } from "../../infrastructure/LamfoApiPostRepository";
import { LamfoApiPostResponse, Post } from "../../infrastructure/LamfoApiResponses";
import { createEmptyPost, createEmptyPostReponse } from "../../infrastructure/LamfoEmptyThings";
import { getDaysDifference } from '../../logic/getDaysDifference';
import Skeleton from 'react-loading-skeleton';
import { ManagersRanking } from '../../components/managersRanking/ManagersRanking';
import { Link } from 'react-router-dom';

const repository = new LamfoApiPostRepository();

export function Home() {
    const [postsResponse, setPostsResponse] = useState<LamfoApiPostResponse>(createEmptyPostReponse());
    const [isLoadingNews, setIsLoadingNews] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedPost, setSelectedPost] = useState<Post>(createEmptyPost());

    useEffect(() => {
        setIsLoadingNews(true);
        repository
            .getPosts(10)
            .then((postsApiResponse) => {
                setPostsResponse(postsApiResponse);
                setIsLoadingNews(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [repository]);

    const handleShowModal = (post: any) => {
        setSelectedPost(post);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedPost(createEmptyPost());
    };

    return (
        <React.Fragment>
            {isLoadingNews ? (
                <Row>
                    <Col className='p-0'>
                        <Skeleton height={200} />
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Carousel className={styles.carouselmain}>
                        {postsResponse.posts.slice(0, 5).map((item, index) => (
                            <Carousel.Item
                                className={styles.carouselitem}
                                key={index}
                                onClick={() => handleShowModal(item)}
                            >
                                <Image
                                    className={styles.carouselimage}
                                    src={item.image_url}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src="/img/more_news.jpg";
                                    }}
                                />
                                <Carousel.Caption>
                                    <h3>{item.title}</h3>
                                    <p className={styles.carouselauthor}>{item.author.name}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Row>
            )}
            {isLoadingNews ? (
                <Row className="mt-1">
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <Col key={index} className="p-0" md={4}>
                            <Card
                                className={styles.newscard}
                            >
                                <Skeleton height={112} />
                            </Card>
                        </Col>
                    ))}
                    <Col className="p-0" md={4}>
                        <a href={process.env.REACT_APP_LAMFO_OLD_URL + 'lamfogram.php'}>
                            <Card
                                className={styles.newscard}
                            >
                                <Card.Img
                                    className={styles.carouselimage}
                                    src="/img/more_news.jpg"
                                />
                                <Card.ImgOverlay>
                                    <Card.Title>Mas noticias...</Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                        </a>
                    </Col>
                </Row>
            ) : (
                <Row className="mt-1">
                    {postsResponse.posts.slice(5, 10).map((item, index) => (
                        <Col key={index} className="p-0" md={4}>
                            <Card
                                className={styles.newscard}
                                onClick={() => handleShowModal(item)}
                            >
                                <Card.Img
                                    className={styles.carouselimage}
                                    src={item.image_url}
                                />
                                <Card.ImgOverlay>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text>{item.author.name}</Card.Text>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>
                    ))}
                    <Col className="p-0" md={4}>
                        <a href={process.env.REACT_APP_LAMFO_OLD_URL + 'lamfogram.php'}>
                            <Card
                                className={styles.newscard}
                            >
                                <Card.Img
                                    className={styles.carouselimage}
                                    src="/img/more_news.jpg"
                                />
                                <Card.ImgOverlay>
                                    <Card.Title>Mas noticias...</Card.Title>
                                </Card.ImgOverlay>
                            </Card>
                        </a>
                    </Col>
                </Row>
            )}
            <Row className="mt-1">
                <Col className="p-0" md={4}>
                    <ManagersRanking limit={5} />
                    <Link to="/ranking">
                        <Button className='w-100' size='lg' variant="primary">RANKING</Button>
                    </Link>
                </Col>
            </Row>

			<Modal show={showModal} onHide={handleCloseModal} centered>
                {selectedPost && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>{selectedPost.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className={styles.modalAuthorInfo}>
                                <Image
                                    src={selectedPost.author.avatar}
                                    className={styles.authorAvatar}
                                    rounded={selectedPost.author.type == 'person'}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src="/img/avatar.png";
                                    }}
                                />
                                <div>
                                    <p className={styles.authorName}>{selectedPost.author.name}</p>
                                    <p className={styles.publishedDate}>{getDaysDifference(selectedPost.created_at)}</p>
                                </div>
                            </div>
                            <Image
                                fluid
                                className={styles.modalImage}
                                src={selectedPost.image_url}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src="/img/more_news.jpg";
                                }}
                            />
                            <div 
                                className={styles.modalContent}
                                dangerouslySetInnerHTML={{ __html: selectedPost.content }}
                            />
                        </Modal.Body>
                    </>
                )}
            </Modal>
        </React.Fragment>
    );
}

export default Home;
