import React, { useEffect, useState } from "react";

import { LamfoApiManagersRankingResponse } from "../../infrastructure/LamfoApiResponses";
import styles from "./managersranking.module.scss";
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { Badge, Col, Row, Table, Toast } from "react-bootstrap";
import { createEmptyRankingReponse } from "../../infrastructure/LamfoEmptyThings";
import { LamfoApiUserRepository } from "../../infrastructure/LamfoApiUserRepository";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaMinus, FaPlus } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const repository = new LamfoApiUserRepository();

export function ManagersRanking(props: {
    limit?: number,
}) {
    const { limit } = props;
    const [managersRankingResponse, setmanagersRankingResponse] = useState<LamfoApiManagersRankingResponse>(createEmptyRankingReponse());    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showToastIndex, setShowToastIndex] = useState<number | null>(null);
    
    const toggleShowToast = (index: number) => {
        setShowToastIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    useEffect(() => {
        setIsLoading(true);
        repository
            .getManagerRanking(limit)
            .then((managerRankingResponse) => {
                setmanagersRankingResponse(managerRankingResponse);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [repository]);

    return (
        <>
            {isLoading && Array.from({ length: 15 }, (_, index) => (
                <Card key={index} className={styles.rankingcard}>
                    <Card.Header className={styles.cardheader}>
                        <Row>
                            <Col className="col-3 p-0 text-center d-flex align-items-center">
                                <div className={styles.rankingposition}>
                                    {index + 1}
                                </div>
                            </Col>
                            <Col className="col-2 p-0 text-center">
                                <Image
                                    src={'/img/avatar.png'}
                                    roundedCircle
                                    className={styles.avatar}
                                />
                            </Col>
                            <Col className="col-5 p-0">
                                <div className={styles.playertext}>
                                    <Skeleton width={100} />
                                </div>
                                <div className={`${styles.playertext} d-flex align-items-center`}>
                                    <FaPlus className={styles.toasticon} />
                                    <div>PRM: <Skeleton width={40} /></div>
                                </div>
                            </Col>
                            <Col className="col-2 p-0 text-center">
                                <Image
                                    src={'/img/shield.png'}
                                    className={styles.teamlogo}
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                </Card>
            ))}
            {!isLoading && managersRankingResponse?.ranking.map((ranking, index) => (
                <Card key={index} className={styles.rankingcard}>
                    <Card.Header className={styles.cardheader}>
                        <Row>
                            <Col className="col-3 p-0 text-center d-flex align-items-center">
                                <div className={styles.rankingposition}>
                                    {index + 1}
                                </div>
                                {ranking.last_week_position &&
                                (
                                    ranking.last_week_position > (index + 1) ?
                                        <Badge className={styles.positionbadge} pill bg="success">
                                            <FaAngleDoubleUp />{ranking.last_week_position - (index + 1)}
                                        </Badge> :
                                        (
                                            ranking.last_week_position < (index + 1) ?
                                                <Badge className={styles.positionbadge} pill bg="danger">
                                                    <FaAngleDoubleDown />{(index + 1) - ranking.last_week_position}
                                                </Badge> :
                                                null
                                        )
                                )}
                            </Col>
                            <Col className="col-2 p-0 text-center">
                                <Image
                                    src={ranking.manager.avatar ?? '/img/avatar.png'}
                                    roundedCircle
                                    className={styles.avatar}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src="/img/avatar.png";
                                    }}
                                />
                            </Col>
                            <Col className="col-5 p-0">
                                <div className={styles.playertext}>
                                    {ranking.manager.name}
                                </div>
                                    <div className={`${styles.playertext} d-flex align-items-center`}>
                                        {
                                            showToastIndex !== index
                                                ? <FaPlus className={styles.toasticon} onClick={() => toggleShowToast(index)} />
                                                : <FaMinus className={styles.toasticon} onClick={() => toggleShowToast(index)} />
                                        }
                                        <div>PRM: {ranking.total_points}</div>
                                    </div>
                            </Col>
                            <Col className="col-2 p-0 text-center">
                                <Image
                                    src={ranking.manager.team.shield ?? '/img/shield.png'}
                                    className={styles.teamlogo}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src="/img/shield.png";
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Toast className="mt-2 p-1 w-100" show={showToastIndex === index} onClose={() => toggleShowToast(index)}>
                                <Toast.Body className="p-1">
                                    <Table hover size="sm">
                                        <tbody>
                                            {ranking.ranking_items.length ?
                                                ranking.ranking_items.map((item, index) => {
                                                    let itemContent;
                                                    
                                                    switch (item.type) {
                                                        case "points":
                                                            itemContent = `${item.value} puntos en ${item.competition}`;
                                                            break;
                                                        case "rounds":
                                                            itemContent = `${item.value} rondas superadas en ${item.competition}`;
                                                            break;
                                                        case "champion":
                                                            itemContent = `Campeón ${item.competition}`;
                                                            break;
                                                        case "promotion":
                                                            itemContent = `Ascenso de ${item.competition} ${item.season}`;
                                                            break;
                                                        case "relegation":
                                                            itemContent = `Descenso de ${item.competition} ${item.season}`;
                                                            break
                                                        case "goals":
                                                            itemContent = `${item.value}% de los objetivos cumplidos`;
                                                            break;
                                                        case "teamsheet_perfect":
                                                            itemContent = 'Ningún error en las alineaciones';
                                                            break;
                                                        case "teamsheet_errors":
                                                            itemContent = `${item.value} errores en las alineaciones`;
                                                            break;
                                                        case "economy":
                                                            if (item.value > 0) {
                                                                itemContent = 'Objetivo económico cumplido';
                                                            } else {
                                                                itemContent = `Resultado económico de la temporada: ${item.value.toLocaleString()}`;
                                                            }
                                                            break;
                                                        case "economy_infringements":
                                                            itemContent = `${item.value.toLocaleString()} pagado en concepto de multas`;
                                                            break;
                                                        case "player_scorer":
                                                            itemContent = 'Máximo goleador de la temporada';
                                                            break;
                                                        case "player_asister":
                                                            itemContent = 'Máximo asistidor de la temporada';
                                                            break;
                                                        case "top_scoring_team":
                                                            itemContent = 'Equipo más goleador de la temporada';
                                                            break;
                                                        case "top_defensive_team":
                                                            itemContent = 'Equipo menos goleado de la temporada';
                                                            break;
                                                        default:
                                                            itemContent = `${item.type} - ${item.competition} - ${item.value} - ${item.ranking_points}`;
                                                            break;
                                                    }
                                            
                                                    return (
                                                        <tr key={index} className={styles.rankingitem}>
                                                            <td>
                                                                <Image
                                                                    src={item.team.shield ?? '/img/shield.png'}
                                                                    className={styles.itemteamlogo}
                                                                    onError={({ currentTarget }) => {
                                                                        currentTarget.onerror = null;
                                                                        currentTarget.src="/img/shield.png";
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {item.season}
                                                            </td>
                                                            <td>
                                                                {itemContent}
                                                            </td>
                                                            <td className="text-end">
                                                                <strong>{item.ranking_points}</strong>
                                                            </td>
                                                        </tr>
                                                    );
                                                }) :
                                                <tr>
                                                    <td colSpan={3} className="text-center">Sin puntos</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Toast.Body>
                            </Toast>
                        </Row>
                    </Card.Header>
                </Card>
            ))}
        </>
    );
}