import React, { useState, useEffect } from "react";
import { Form, Button, InputGroup, Dropdown, DropdownButton } from "react-bootstrap";

interface MoneyFormFieldProps {
    predefinedValues?: number[];
    step?: number;
    min?: number;
    max?: number;
    value: number;
    onChange: (value: number) => void;
}

const MoneyFormField: React.FC<MoneyFormFieldProps> = ({
    predefinedValues = Array.from({ length: 401 }, (_, i) => i * 100_000),
    step = 100_000,
    min = 0,
    max = 199_000_000,
    value,
    onChange,
}) => {
    const [inputValue, setInputValue] = useState<string>("");
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        if (!isEditing) {
        setInputValue(formatValue(value));
        }
    }, [value, isEditing]);

    const formatValue = (num: number): string => {
        if (num >= 1_000_000) {
            const formatted = (num / 1_000_000).toFixed(
                num % 100_000 === 0 ? 1 : 2
            );
            return `${formatted}M`;
        }
        if (num >= 1_000) return `${(num / 1_000).toFixed(0)}K`;
            return num.toString();
    };

    const parseValue = (formattedValue: string): number => {
        if (formattedValue.includes("M")) {
            return parseFloat(formattedValue.replace("M", "")) * 1_000_000;
        } else if (formattedValue.includes("K")) {
            return parseFloat(formattedValue.replace("K", "")) * 1_000;
        }
            return parseInt(formattedValue.replace(/\D/g, ""), 10) || 0;
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const rawValue = e.target.value;
        setInputValue(rawValue);
        setIsEditing(true);
        const parsedValue = parseValue(rawValue);
        if (!isNaN(parsedValue)) {
        onChange(Math.min(Math.max(parsedValue, min), max));
        }
    };

    const handleInputBlur = (): void => {
        setIsEditing(false);
        setInputValue(formatValue(value));
    };

    const findClosestValue = (val: number): number => {
        return predefinedValues.reduce((closest, current) =>
        Math.abs(current - val) < Math.abs(closest - val) ? current : closest
        );
    };

    const incrementValue = (): void => {
        const closestValue = findClosestValue(value);
        const currentIndex = predefinedValues.indexOf(closestValue);
        const nextValue =
        currentIndex < predefinedValues.length - 1
            ? predefinedValues[currentIndex + 1]
            : Math.min(value + step, max);
        onChange(nextValue);
    };

    const decrementValue = (): void => {
        const closestValue = findClosestValue(value);
        const currentIndex = predefinedValues.indexOf(closestValue);
        const prevValue =
        currentIndex > 0
            ? predefinedValues[currentIndex - 1]
            : Math.max(value - step, min);
        onChange(prevValue);
    };

    return (
        <InputGroup className="mt-1">
            <Form.Control
                type="text"
                required
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                style={{ textAlign: "right" }}
            />
            <DropdownButton
                variant="outline-secondary"
                title=""
                id="input-dropdown"
                align="end"
            >
                {predefinedValues.map((val) => (
                <Dropdown.Item key={val} onClick={() => onChange(val)}>
                    {formatValue(val)}
                </Dropdown.Item>
                ))}
            </DropdownButton>
            <Button variant="outline-secondary" onClick={decrementValue} disabled={value <= min}>
                -
            </Button>
            <Button variant="outline-secondary" onClick={incrementValue} disabled={value >= max}>
                +
            </Button>
        </InputGroup>
    );
};

export default MoneyFormField;
